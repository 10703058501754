import React from "react";
import CardCaracteristics from "../components/CardCaracteristics";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

function Residencial() {
  return (
    <>
      <li>Residencial</li>
      <li>Remodelación y ampliación en espacios interiores y exteriores</li>
    </>
  );
}
function Comercial() {
  return (
    <>
      <li>Estructuras de concreto armado</li>
      <li>Estructuras de concreto y acero</li>
      <li>Remodelación de espacios interiores y exteriores</li>
      <li>Mantenimiento de edificio e instalaciones</li>
    </>
  );
}
function Industrial() {
  return (
    <>
      <li>Estructura de acero y herrería</li>
      <li>Estructuras de concreto armado</li>
      <li>Instalación de diferentes tipos de cubierta</li>
      <li>
        Construcción de pisos industriales pulidos y para patios de embarque
      </li>
      <li>Remodelación y adecuación de áreas de trabajo</li>
      <li>Mantenimiento de edificio e instalaciones</li>
    </>
  );
}
function Urbanizacion() {
  return (
    <>
      <li>Infraestructura de agua potable</li>
      <li>Infraestructura de drenaje</li>
      <li>Terracerías</li>
      <li>Construcción de pavimentos, guarniciones y banquetas</li>
    </>
  );
}
function Servicios() {
  return (
    <>
      <li>Diseño y calculo estructural </li>
      <li>Supervisión de obra</li>
      <li>Tramite de permisos y licencias en el municipio de Nogales Sonora</li>
    </>
  );
}
function Caracteristics() {
  return (
    <div className="section-grey" id="services">
      <div className="container">
        <div className="pb-50 centered">
          <h1>Nuestros servicios</h1>
          <p>
            Nuestros servicios abarcan desde el diseño arquitectónico hasta la
            construcción completa. Nos especializamos en ofrecer soluciones
            integrales, adaptadas a las necesidades individuales de cada
            cliente. Cada servicio está respaldado por nuestra experiencia,
            calidad y compromiso con la excelencia.
          </p>
        </div>
        <div>
          <Row>
          <Col style={{ marginBottom: "15px" }}>
            <CardCaracteristics
              Icon={"/img/icons/s3.png"}
              Title={"Comercial"}
              Text={<Comercial />}
            />
          </Col>
          <Col style={{ marginBottom: "15px" }}>
            <CardCaracteristics
              Icon={"/img/icons/s2.png"}
              Title={"Industrial"}
              Text={<Industrial />}
            />
            </Col>
          </Row>
          <Row>
          <Col style={{ marginBottom: "15px" }}>
            <CardCaracteristics
              Icon={"/img/icons/s4.png"}
              Title={"Residencial"}
              Text={<Residencial />}
            />
          </Col>
          <Col style={{ marginBottom: "15px" }}>
            <CardCaracteristics
              Icon={"/img/icons/s1.png"}
              Title={"URBANIZACIÓN"}
              Text={<Urbanizacion />}
            />
          </Col>
          <Col style={{ marginBottom: "15px" }}>
            <CardCaracteristics
              Icon={"/img/icons/s5.png"}
              Title={"Servicios"}
              Text={<Urbanizacion />}
            ></CardCaracteristics>
          </Col>
          </Row>

         
        </div>
      </div>
    </div>
  );
}

export default Caracteristics;
