import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillTwitterSquare } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";

import { BsFillTelephoneFill } from "react-icons/bs";
import { BsEnvelopeFill } from "react-icons/bs";

function Footer() {
  return (
    <div className="section footer">
      <div className="container">
        <Row>
          <Col sm="4" className="">
            <div className="d-flex align-items-center logo-footer ">
              <img
                src="/img/logo/logo-yellow.png"
                alt="Corella Logo"
                className="d-inline-block align-top"
              ></img>
            </div>
              <p className="">
                Corella Diseño y Construcción - Construyendo tus sueños con
                solidez y excelencia.
              </p>
          </Col>
          <Col sm="4">
            <h3>Contacto</h3>
            <ul className="list-unstyled footer-link mail">
              <li className="d-flex">
                <span className="text-black">
                  <Row>
                    <Col sm={1}>
                      <BsFillTelephoneFill />
                    </Col>
                    <Col>
                      <p style={{ marginBottom: "0px" }}>Mario Corella</p>
                      <p>+52 631 113 0303</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <BsFillTelephoneFill />
                    </Col>
                    <Col>
                      <p style={{ marginBottom: "0px" }}>Martín Corella</p>
                      <p>+52 631 126 56 52</p>
                    </Col>
                  </Row>
                </span>
              </li>
              <li className="d-flex">
                <span className="text-black">
                  <Row>
                    <Col sm={1}>
                      <BsEnvelopeFill />
                    </Col>
                    <Col>
                      <p style={{ marginBottom: "0px" }}>Mario Corella</p>
                      <p>mario.ca@construccionescorella.com</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}>
                      <BsEnvelopeFill />
                    </Col>
                    <Col>
                      <p style={{ marginBottom: "0px" }}>Martín Corella</p>
                      <p>martin.cv@construccionescorella.com</p>
                    </Col>
                  </Row>
                </span>
              </li>
            </ul>
          </Col>
          <Col sm="4">
            <h3>Secciones</h3>
            <ul className="list-unstyled footer-link">
              <li>
                <a href="#home">Inicio</a>
              </li>
              <li>
                <a href="#services">Servicios</a>
              </li>
              <li>
                <a href="#proyectos">Proyectos</a>
              </li>
              <li>
                <a href="#aboutus">Sobre nosotros</a>
              </li>
              {/*<li>
                <a href="#">Testimonios</a>
              </li>*/}
              <li>
                <a href="#contact-us">Contáctanos</a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <p className="copyright">
            Copyright © 2023 Todos los derechos reservados
          </p>
        </Row>
      </div>
    </div>
  );
}

export default Footer;
