import React from "react";

import { Col } from "react-bootstrap";

function Caracteristics() {
  return (
    <div className="call-to-action">
      <div className="container">
        <div className="text center-div card-container">
          <Col>
            <h3>¿Listo para dar vida a tus proyectos con nosotros?</h3>
          </Col>
          <Col className="text-end">
            <a className="btn-white" href="#contact-us" style={{height:"55px !important"}}>Obtén una cotización</a>
          </Col>
        </div>
      </div>
    </div>
  );
}

export default Caracteristics;
