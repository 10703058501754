import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Modal } from "react-bootstrap";
import { FaSearchPlus } from "react-icons/fa";
const SingleProject = () => {
  const { projectId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const projectList = [
    {
      id: "remodelacion-de-hogar",
      title: "Remodelación de hogar",
      text: "Se llevó a cabo la remodelación completa de la fachada de la casa, renovando y mejorando la estructura.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p1/p1-5.jpg",
      images: [
        "../img/projects/p1/p1-1.jpg",
        "../img/projects/p1/p1-2.jpg",
        "../img/projects/p1/p1-3.jpg",
        "../img/projects/p1/p1-4.jpg",
        "../img/projects/p1/p1-5.jpg",
      ],
    },
    {
      id: "cubierta-de-lamina",
      title: "Cubierta de lámina",
      text: 'Cambio de cubierta en nave industrial de 1900m2, a base de lámina galvanizada kr-18 rolada en sitio e instalación de aislamiento MBI con acabado vinílico de 3"',
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p2/p2-4.jpeg",
      images: [
        "../img/projects/p2/p2-1.jpeg",
        "../img/projects/p2/p2-2.jpeg",
        "../img/projects/p2/p2-3.jpeg",
        "../img/projects/p2/p2-4.jpeg",
        "../img/projects/p2/p2-5.jpeg",
        "../img/projects/p2/p2-6.jpeg",
        "../img/projects/p2/p2-7.jpeg",
        "../img/projects/p2/p2-8.jpeg",
      ],
    },
    {
      id: "invernadero",
      title: "Invernadero",
      text: "Construcción de cimentación y obra civil de invernadero prefabricado ultra clima por empresa KUBO",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p3/p3-14.png",
      images: [
        "../img/projects/p3/p3-1.jpg",
        "../img/projects/p3/p3-2.jpg",
        "../img/projects/p3/p3-3.jpg",
        "../img/projects/p3/p3-4.jpg",
        "../img/projects/p3/p3-5.jpg",
        "../img/projects/p3/p3-6.jpg",
        "../img/projects/p3/p3-7.jpg",
        "../img/projects/p3/p3-8.jpg",
        "../img/projects/p3/p3-9.jpg",
        "../img/projects/p3/p3-11.jpg",
        "../img/projects/p3/p3-12.jpg",
        "../img/projects/p3/p3-13.jpg",
        "../img/projects/p3/p3-14.png",
        "../img/projects/p3/p3-15.jpg",
        "../img/projects/p3/p3-16.jpg",
        "../img/projects/p3/p3-17.jpg",
        "../img/projects/p3/p3-18.jpg",
        "../img/projects/p3/p3-19.jpg",
        "../img/projects/p3/p3-20.jpg",
        "../img/projects/p3/p3-21.jpg",
        "../img/projects/p3/p3-22.jpg",
        "../img/projects/p3/p3-23.jpg",
        "../img/projects/p3/p3-24.jpg",
        "../img/projects/p3/p3-25.jpg",
        "../img/projects/p3/p3-26.jpg",
        "../img/projects/p3/p3-27.jpg",
        "../img/projects/p3/p3-28.jpg",
        "../img/projects/p3/p3-29.jpg",
        "../img/projects/p3/p3-30.jpg",
      ],
    },
    {
      id: "remodelacion-de-garita",
      title: "Remodelación de garita",
      text: "Terminada remodelación de garita internacional para mejoras en control y servicio fronterizo.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p4/p4-3.jpg",
      images: [
        "../img/projects/p4/p4-1.jpg",
        "../img/projects/p4/p4-2.jpg",
        "../img/projects/p4/p4-3.jpg",
        "../img/projects/p4/p4-4.jpg",
        "../img/projects/p4/p4-5.jpg",
        "../img/projects/p4/p4-6.jpg",
        "../img/projects/p4/p4-7.jpg",
      ],
    },
    {
      id: "reparacion-de-puente",
      title: "Reparación de puente",
      text: "Trabajos de estabilización de estructuras dañadas",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p5/p5-4.jpg",
      images: [
        "../img/projects/p5/p5-1.jpg",
        "../img/projects/p5/p5-2.jpg",
        "../img/projects/p5/p5-3.jpg",
        "../img/projects/p5/p5-4.jpg",
        "../img/projects/p5/p5-5.jpg",
        "../img/projects/p5/p5-6.jpg",
        "../img/projects/p5/p5-7.jpg",
        "../img/projects/p5/p5-8.jpg",
        "../img/projects/p5/p5-9.jpg",
        "../img/projects/p5/p5-10.jpg",
        "../img/projects/p5/p5-11.jpg",
        "../img/projects/p5/p5-12.jpg",
        "../img/projects/p5/p5-13.jpg",
        "../img/projects/p5/p5-14.jpg",
        "../img/projects/p5/p5-15.jpg",
        "../img/projects/p5/p5-16.jpg",
        "../img/projects/p5/p5-17.jpg",
        "../img/projects/p5/p5-18.jpg",
        "../img/projects/p5/p5-19.jpg",
      ],
    },
    {
      id: "reparacion-de-plataforma",
      title: "Reparación de plataforma",
      text: "Reparación de plataforma de importación y exportación en aduana de Nogales Sonora",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p6/p6-1.jpg",
      images: [
        "../img/projects/p6/p6-1.jpg",
        "../img/projects/p6/p6-2.jpg",
        "../img/projects/p6/p6-3.jpg",
        "../img/projects/p6/p6-4.jpg",
        "../img/projects/p6/p6-5.jpg",
        "../img/projects/p6/p6-6.jpeg",
        "../img/projects/p6/p6-7.jpeg",
        "../img/projects/p6/p6-8.jpeg",
      ],
    },
    {
      id: "instalacion-de-piso-de-adoquin",
      title: "Instalación de piso de adoquín",
      text: "Piso de adoquines instalado para renovar el área exterior.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p7/p7-1.jpg",
      images: [
        "../img/projects/p7/p7-1.jpg",
        "../img/projects/p7/p7-2.jpg",
        "../img/projects/p7/p7-3.jpg",
      ],
    },
    {
      id: "instalacion-de-juegos",
      title: "Instalación de juegos",
      text: "Parque renovado con nuevos juegos para disfrutar al aire libre.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p8/p8-1.jpg",
      images: [
        "../img/projects/p8/p8-1.jpg",
        "../img/projects/p8/p8-2.jpg",
        "../img/projects/p8/p8-3.jpg",
      ],
    },
    {
      id: "construccion-de-nave-industrial",
      title: "Creación de nave industrial",
      text: "Construcción de cimentación, obra civil e instalaciones pluviales para nave industrial de 1,536m2",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p9/p9-22.jpg",
      images: [
        "../img/projects/p9/p9-1.jpg",
        "../img/projects/p9/p9-2.jpg",
        "../img/projects/p9/p9-3.jpg",
        "../img/projects/p9/p9-4.jpg",
        "../img/projects/p9/p9-5.jpg",
        "../img/projects/p9/p9-6.jpg",
        "../img/projects/p9/p9-7.jpg",
        "../img/projects/p9/p9-8.jpg",
        "../img/projects/p9/p9-9.jpg",
        "../img/projects/p9/p9-10.jpg",
        "../img/projects/p9/p9-11.jpg",
        "../img/projects/p9/p9-12.jpg",
        "../img/projects/p9/p9-13.jpg",
        "../img/projects/p9/p9-14.jpg",
        "../img/projects/p9/p9-15.jpg",
        "../img/projects/p9/p9-16.jpg",
        "../img/projects/p9/p9-17.jpg",
        "../img/projects/p9/p9-18.jpg",
        "../img/projects/p9/p9-19.jpg",
        "../img/projects/p9/p9-20.jpg",
        "../img/projects/p9/p9-21.jpg",
        "../img/projects/p9/p9-22.jpg",
        "../img/projects/p9/p9-23.jpg",
        "../img/projects/p9/p9-24.jpg",
        "../img/projects/p9/p9-25.jpg",
        "../img/projects/p9/p9-26.jpg",
        "../img/projects/p9/p9-27.jpg",
      ],
    },
    {
      id: "reparacion-de-socavon",
      title: "Reparación de socavón",
      text: "Reparación de socavón en calle 5 de febrero en Nogales Sonora",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p10/p10-1.jpg",
      images: [
        "../img/projects/p10/p10-1.jpg",
        "../img/projects/p10/p10-2.jpg",
        "../img/projects/p10/p10-3.jpg",
        "../img/projects/p10/p10-4.jpg",
        "../img/projects/p10/p10-5.jpg",
        "../img/projects/p10/p10-6.jpg",
        "../img/projects/p10/p10-7.jpg",
        "../img/projects/p10/p10-8.jpg",
        "../img/projects/p10/p10-9.jpg",
        "../img/projects/p10/p10-10.jpg",
        "../img/projects/p10/p10-11.jpg",
        "../img/projects/p10/p10-12.jpg",
      ],
    },
    {
      id: "construccion-de-kiosko",
      title: "Construcción de kiosko",
      text: "Construcción moderna de kiosco funcional.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p11/p11-1.jpg",
      images: [
        "../img/projects/p11/p11-1.jpg",
        "../img/projects/p11/p11-2.jpg",
        "../img/projects/p11/p11-3.jpg",
        "../img/projects/p11/p11-4.jpg",
        "../img/projects/p11/p11-5.jpg",
        "../img/projects/p11/p11-6.jpg",
        "../img/projects/p11/p11-7.jpg",
        "../img/projects/p11/p11-8.jpg",
        "../img/projects/p11/p11-9.jpg",
        "../img/projects/p11/p11-10.jpg",
        "../img/projects/p11/p11-11.jpg",
        "../img/projects/p11/p11-12.jpg",
        "../img/projects/p11/p11-13.jpg",
      ],
    },
    {
      id: "muro-de-contencion",
      title: "Muro de contención",
      text: "Construcción de muro de contención de piedra.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p12/p12-1.jpg",
      images: [
        "../img/projects/p12/p12-1.jpg",
        "../img/projects/p12/p12-2.jpg",
        "../img/projects/p12/p12-3.jpg",
        "../img/projects/p12/p12-4.jpg",
        "../img/projects/p12/p12-5.jpg",
        "../img/projects/p12/p12-6.jpg",
        "../img/projects/p12/p12-7.jpg",
        "../img/projects/p12/p12-8.jpg",
        "../img/projects/p12/p12-9.jpg",
      ],
    },
    {
      id: "pavimentacion-de-calle",
      title: "Pavimentación de calle",
      text: "Pavimentación de calle José Lopez Portillo en Nogales Sonora",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p13/p13-1.jpg",
      images: [
        "../img/projects/p13/p13-1.jpg",
        "../img/projects/p13/p13-2.jpg",
        "../img/projects/p13/p13-3.jpg",
        "../img/projects/p13/p13-4.jpg",
        "../img/projects/p13/p13-5.jpg",
        "../img/projects/p13/p13-6.jpg",
        "../img/projects/p13/p13-7.jpg",
        "../img/projects/p13/p13-8.jpg",
        "../img/projects/p13/p13-9.jpg",
        "../img/projects/p13/p13-10.jpg",
        "../img/projects/p13/p13-11.jpg",
        "../img/projects/p13/p13-12.jpg",
        "../img/projects/p13/p13-13.jpg",
        "../img/projects/p13/p13-14.jpg",
        "../img/projects/p13/p13-15.jpg",
        "../img/projects/p13/p13-16.jpg",
        "../img/projects/p13/p13-17.jpg",
        "../img/projects/p13/p13-18.jpg",
        "../img/projects/p13/p13-19.jpg",
        "../img/projects/p13/p13-20.jpg",
        "../img/projects/p13/p13-21.jpg",
        "../img/projects/p13/p13-22.jpg",
        "../img/projects/p13/p13-23.jpg",
        "../img/projects/p13/p13-24.jpg",
        "../img/projects/p13/p13-25.jpg",
        "../img/projects/p13/p13-26.jpg",
        "../img/projects/p13/p13-27.jpg",
      ],
    },
    {
      id: "tanque-de-agua-5000m3",
      title: "Tanque de agua de 5,000 m³",
      text: "Construcción de cimentación para tanque de agua de 5,000 m³ a base de concreto armado.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p14/p14-1.jpg",
      images: [
        "../img/projects/p14/p14-1.jpg",
        "../img/projects/p14/p14-2.jpg",
        "../img/projects/p14/p14-3.jpg",
        "../img/projects/p14/p14-4.jpg",
        "../img/projects/p14/p14-5.jpg",
        "../img/projects/p14/p14-6.jpg",
        "../img/projects/p14/p14-7.jpg",
        "../img/projects/p14/p14-8.jpg",
        "../img/projects/p14/p14-9.jpg",
        "../img/projects/p14/p14-10.jpg",
        "../img/projects/p14/p14-11.jpg",
        "../img/projects/p14/p14-12.jpg",
        "../img/projects/p14/p14-13.jpg",
        "../img/projects/p14/p14-14.jpg",
        "../img/projects/p14/p14-15.jpg",
        "../img/projects/p14/p14-16.jpg",
        "../img/projects/p14/p14-17.jpg",
        "../img/projects/p14/p14-18.jpg",
        "../img/projects/p14/p14-19.jpg",
        "../img/projects/p14/p14-20.jpg",
        "../img/projects/p14/p14-21.jpg",
        "../img/projects/p14/p14-22.jpg",
        "../img/projects/p14/p14-23.jpg",
        "../img/projects/p14/p14-24.jpg",
        "../img/projects/p14/p14-25.jpg",
        "../img/projects/p14/p14-26.jpg",
        "../img/projects/p14/p14-27.jpg",
        "../img/projects/p14/p14-28.jpg",
        "../img/projects/p14/p14-29.jpg",
        "../img/projects/p14/p14-30.jpeg",
        "../img/projects/p14/p14-31.jpeg",
        "../img/projects/p14/p14-32.jpeg",
        "../img/projects/p14/p14-33.jpeg",
      ],
    },
    {
      id: "construccion-de-area-limpieza",
      title: "Construcción de área de limpieza",
      text: "Construcción de área de limpieza a base de multymuro y multytecho.",
      subtitle: "Nogales | Sonora",
      image: "../img/projects/p15/p15-1.jpg",
      images: [
        "../img/projects/p15/p15-1.jpg",
        "../img/projects/p15/p15-2.jpg",
        "../img/projects/p15/p15-3.jpg",
        "../img/projects/p15/p15-4.jpg",
        "../img/projects/p15/p15-5.jpg",
        "../img/projects/p15/p15-6.jpg",
        "../img/projects/p15/p15-7.jpg",
        "../img/projects/p15/p15-8.jpg",
        "../img/projects/p15/p15-9.jpg",
        "../img/projects/p15/p15-10.jpg",
      ],
    },    
  ];

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };
  const showModalWithImage = (imageUrl) => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const project = projectList.find((project) => project.id === projectId);

  if (!project) {
    return <div>Proyecto no encontrado</div>;
  }
  return (
    <>
      <div className="section-grey" style={{ paddingTop: "50px" }}>
        <div className="container">
          <Row style={{ marginBottom: "30px" }}>
            <div className="card-header-project">
              <div className="line-yellow" style={{ marginLeft: "15px" }}>
                <div style={{ marginLeft: "15px" }}>
                  <h1>{project.title}</h1>
                  <span>{project.subtitle}</span>
                  <p>{project.text}</p>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col sm="6">
              <div
                className="container-project-image"
                onClick={() =>
                  showModalWithImage(selectedImage || project.image)
                }
              >
                <img
                  className="single-project-image"
                  src={selectedImage || project.image}
                  alt="Imagen seleccionada"
                ></img>
                <div className="icon-project-image">
                  <FaSearchPlus />
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="grid-project">
                {project.images.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`Imagen ${index}`}
                    onClick={() => handleImageClick(imageUrl)}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
        <Modal.Body style={{ maxHeight: "85vh", overflowY: "auto" }}>
          <img
            className="modal-image"
            src={selectedImage || project.image}
            alt="Imagen en modal"
          ></img>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SingleProject;
