import React, { useState } from "react";

import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Ask from "../components/ask";

function About() {
  const [faqs, setFaqs] = useState([
    {
      question: "Sobre nosotros",
      answer:
        "En Corella Diseño y Construcción, somos más que una empresa constructora; somos un equipo apasionado comprometido con la excelencia en cada proyecto. Desde nuestras raíces como una firma de diseño hasta nuestra evolución como constructores de renombre, nuestra trayectoria está marcada por la innovación, la calidad y el compromiso con cada cliente.",
      open: true,
    },
    {
      question: "Nuestra Misión",
      answer:
        "Nuestra misión es transformar las ideas en estructuras sólidas que superen las expectativas. Nos esforzamos por ser líderes en la industria de la construcción, manteniendo altos estándares de calidad, creatividad y ética laboral. Nos comprometemos a ofrecer soluciones innovadoras y sostenibles que enriquezcan comunidades y superen las necesidades de nuestros clientes.",
      open: false,
    },
    {
      question: "Nuestro Compromiso",
      answer:
        "En Corella Diseño y Construcción, nuestro compromiso es construir más que edificios; buscamos construir relaciones sólidas con nuestros clientes, basadas en la confianza, la transparencia y el respeto mutuo. Nos dedicamos a cada proyecto con integridad, profesionalismo y una búsqueda incansable de la perfección, asegurando así que cada estructura sea un testimonio duradero de nuestra dedicación.",
      open: false,
    },
  ]);
  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <div className="section about" id="aboutus">
      <div className="container">
        <Row>
          <Col>
            <h1 className="yellow">
            Conociendo a  <span>Corella Diseño y Construcción</span>
            </h1>
            <div className="card-contact">
              <div className="faqs">
                {faqs.map((faq, index) => (
                  <Ask
                    faq={faq}
                    index={index}
                    key={index}
                    toggleFAQ={toggleFAQ}
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col className="aboutus-img">
            <img
              className="about-img"
              src="/img/stock/project-leads.webp"
            ></img>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default About;
