import Projects from "./pages/projects";
import MainPage from "./pages/main-page";
import ErrorPage from "./pages/error-page"; // Importa tu página de error 404
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/proyectos/:projectId" element={<Projects />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
