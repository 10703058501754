import React from "react";

const Ask = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
        <div className="line-yellow ">
        <div className="faq-question" style={{marginLeft:"10px"}}><span>{faq.question}</span></div>
        </div>
      
      <div className="faq-answer"><p>{faq.answer}</p> </div>
    </div>
  );
};
export default Ask;
