import React, { useEffect } from "react";
import SingleProject from "../sections/singleproject";
import ContactUs from "../sections/contactus";
import Footer from "../sections/footer";
import NavbarSection from "../components/Navbar";
import WhatsAppButton from "../components/WhatsAppButton";
import { Col } from "react-bootstrap";

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll hacia arriba al cargar la página
  }, []);

  return (
    <div className="header" id="home">
      <NavbarSection />
      <WhatsAppButton
        Href={"5216311130303"}
        Text={"Me gustaria recibir mas informacion"}
      />
      <SingleProject />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Projects;
