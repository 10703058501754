import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

function OurJob() {
  return (
    <div className="section-grey">
      <div className="container">
        <Row>
          <Col sm="6">
            <img className="about-img" src="img/projects/p9/p9-19.jpg"></img>
          </Col>
          <Col>
            <div className="card-contact ourjob-section">
              <h1 className="yellow">
                Nuestra Esencia en <span>Acción</span>
              </h1>
              <p>
                En Corella Diseño y Construcción, nos destacamos por nuestra
                dedicación inquebrantable hacia la excelencia en cada proyecto.
                Nuestra atención meticulosa a los detalles, el compromiso con la
                calidad y la pasión por la innovación nos distinguen. La
                transparencia en nuestras acciones, junto con una comunicación
                clara y un enfoque centrado en el cliente, define nuestra forma
                de trabajar. Cada estructura que construimos es el resultado de
                un compromiso constante con la perfección y la satisfacción del
                cliente. Confía en nosotros para hacer realidad tus sueños
                arquitectónicos con solidez, integridad y creatividad.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default OurJob;
