import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function NavbarSection() {
  const [isSticky, setIsSticky] = useState(false);
  const [expanded, setExpanded] = useState(false); // Estado para manejar el menú desplegable

  const imgBasePath = "/img/logo/"; // Definición de imgBasePath aquí

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const imgLogo = isSticky
    ? `${imgBasePath}corella.png`
    : `${imgBasePath}logo-yellow.png`;

  const stickyClass = isSticky
    ? "background-color-nav sticky-top bg-white shadow border-nav"
    : "";
  const linkColor = isSticky ? "link-sticky" : "nav-links";
  const linkHeight = isSticky ? "navbar-brand-sticky" : "navbar-brand";

  const handleNavClose = () => {
    if (window.innerWidth < 992) {
      setExpanded(false); // Cierra el menú desplegable si el ancho de la ventana es menor que 992px (el breakpoint de colapso de React Bootstrap)
    }
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      className={`navbar navbar-expand-lg  ${stickyClass} `}
    >
      <div className="container">
        <Navbar.Brand
          as={Link}
          to="/"
          className={`${linkHeight} d-flex align-items-center`}
        >
          <img
            src={imgLogo}
            alt="Corella Logo"
            className="d-inline-block align-top"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)} // Maneja el estado del menú desplegable al hacer clic
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="collapse navbar-collapse"
          onSelect={handleNavClose} // Cierra el menú desplegable al seleccionar un enlace
        >
          <Nav className="navbar-nav ms-auto p-4 p-lg-0">
            <Nav.Link
              className={`${linkColor}`}
              href="#home"
              onClick={handleNavClose} // Cierra el menú desplegable al hacer clic
            >
              Inicio
            </Nav.Link>
            <Nav.Link
              className={`${linkColor}`}
              href="#services"
              onClick={handleNavClose} // Cierra el menú desplegable al hacer clic
            >
              Servicios
            </Nav.Link>
            <Nav.Link className={`${linkColor}`} href="#proyectos" onClick={handleNavClose}>
              Proyectos
            </Nav.Link>
            <Nav.Link className={`${linkColor}`} href="#aboutus" onClick={handleNavClose}>
              Nosotros
            </Nav.Link>
            {/*<Nav.Link className={`${linkColor}`} href="#testimonios">
              Testimonios
            </Nav.Link>*/}
            <Nav.Link className={`${linkColor}`} href="#contact-us" onClick={handleNavClose}>
              CONTÁCTANOS
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavbarSection;
