import React from "react";
function CardCaracteristics({ Title, Icon, Text }) {
  return (
    <div className="card-services">
      <img className="icon" src={Icon}></img>
      <h5>{Title}</h5>
      <p>
        <strong>{Text}</strong>
      </p>
    </div>
  );
}
export default CardCaracteristics;
