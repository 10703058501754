import React from "react";
import CardProject from "../components/CardProject";
import Flickity from "react-flickity-component";

function Carousel() {
  const flickityOptions = {
    initialIndex: 2,
    wrapAround: true,
    pageDots: false,
  };
  return (
    <Flickity
      className={"carousel"} // default ''
      elementType={"div"} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static // default false
    >
      <CardProject
        Title="Remodelación de hogar"
        Text={
          "Se llevó a cabo la remodelación completa de la fachada de la casa, renovando y mejorando la estructura."
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p1.png"}
        projectId={"remodelacion-de-hogar"}
      />
      <CardProject
        Title="Cubierta de lámina"
        Text={`Cambio de cubierta en nave industrial de 1900m2, a base de lámina galvanizada kr-18 rolada en sitio e instalación de aislamiento MBI con acabado vinílico de 3"`}
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p2.png"}
        projectId={"cubierta-de-lamina"}
      />
      <CardProject
        Title="Invernadero"
        Text={
          "Construcción de cimentación y obra civil de invernadero prefabricado ultra clima por empresa KUBO"
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p3.png"}
        projectId={"invernadero"}
      />
      <CardProject
        Title="Remodelación de garita"
        Text={
          "Terminada remodelación de garita internacional para mejoras en control y servicio fronterizo."
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p4.png"}
        projectId={"remodelacion-de-garita"}
      />
      <CardProject
        Title="Reparación de puente"
        Text={"Trabajos de estabilización de estructuras dañadas"}
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p5.png"}
        projectId={"reparacion-de-puente"}
      />
      <CardProject
        Title="Reparación de plataforma"
        Text={
          "Reparación de plataforma de importación y exportación en aduana de Nogales Sonora"
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p6.png"}
        projectId={"reparacion-de-plataforma"}
      />
      <CardProject
        Title="Instalación de piso de adoquín"
        Text={"Piso de adoquines instalado para renovar el área exterior."}
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p7.png"}
        projectId={"instalacion-de-piso-de-adoquin"}
      />
      <CardProject
        Title="Instalación de juegos"
        Text={"Parque renovado con nuevos juegos para disfrutar al aire libre."}
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p8.png"}
        projectId={"instalacion-de-juegos"}
      />
      <CardProject
        Title="Construcción de nave industrial"
        Text={
          "Construcción de cimentación, obra civil e instalaciones pluviales para nave industrial de 1,536m2"
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p9.png"}
        projectId={"construccion-de-nave-industrial"}
      />
      <CardProject
        Title="Construcción de nave industrial"
        Text={
          "Construcción de cimentación, obra civil e instalaciones pluviales para nave industrial de 1,536m2"
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p15.png"}
        projectId={"construccion-de-nave-industrial"}
      />{" "}
      <CardProject
        Title="Reparación de socavón"
        Text={
          "Reparación de socavón en calle 5 de febrero Nogales Sonora"
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p10.png"}
        projectId={"reparacion-de-socavon"}
      />{" "}
      <CardProject
        Title="Construcción de kiosko"
        Text={
          "Construcción moderna de kiosco funcional."
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p11.png"}
        projectId={"construccion-de-kiosko"}
      />
      <CardProject
        Title="Muro de contención"
        Text={
          "Construcción de muro de contención de piedra."
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p12.png"}
        projectId={"muro-de-contencion"}
      />
      <CardProject
        Title="Pavimentación de calle"
        Text={
          "Pavimentación de calle José Lopez Portillo"
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p13.png"}
        projectId={"pavimentacion-de-calle"}
      />
      <CardProject
        Title="Tanque de agua de 5,000 m³"
        Text={
          "Construcción de cimentación para tanque de agua de 5,000 m³ a base de concreto armado."
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p14.png"}
        projectId={"tanque-de-agua-5000m3"}
      />
      <CardProject
        Title="Construcción de área de limpieza"
        Text={
          "Construcción de área de limpieza a base de multymuro y multytecho."
        }
        SubTile={"Nogales | Sonora"}
        Image={"../img/projects/p15.png"}
        projectId={"construccion-de-area-limpieza"}
      />
    </Flickity>
  );
}
function Proyects() {
  return (
    <div className="section" id="proyectos">
      <div className="container">
        <h1 className="yellow">
          Explora Nuestros <span>Proyectos</span>
        </h1>
        <p>
          Nuestros servicios abarcan desde el diseño arquitectónico hasta la
          construcción completa. Nos especializamos en ofrecer soluciones
          integrales, adaptadas a las necesidades individuales de cada cliente.
          Cada servicio está respaldado por nuestra experiencia, calidad y
          compromiso con la excelencia.
        </p>
      </div>
      <Carousel />
    </div>
  );
}

export default Proyects;
