import React, { useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import emailjs from "emailjs-com";

function ContactForm() {
  const [showModal, setShowModal] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    from_name: "",
    phone: "",
    reply_to: "",
    message: "",
  });

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      from_name: e.target[0].value,
      phone: e.target[1].value,
      reply_to: e.target[2].value,
      message: e.target[3].value,
    };

    const newErrors = {};

    if (formData.from_name.trim() === "") {
      newErrors.from_name = "Por favor, ingrese su nombre.";
    } else {
      newErrors.from_name = "";
    }

    if (
      formData.message.trim() === ""
    ) {
      newErrors.message = "Por favor, ingrese un mensaje.";
    } else {
      newErrors.message = "";
    }

    if (
      !validateEmail(formData.reply_to)
    ) {
      newErrors.reply_to = "Por favor, ingrese un correo electrónico válido.";
    } else {
      newErrors.reply_to = "";
    }

    if (
      formData.phone.trim().length !== 10 ||
      isNaN(formData.phone.trim())
    ) {
      newErrors.phone =
        "Por favor, ingrese un número de teléfono válido (10 dígitos).";
    } else {
      newErrors.phone = "";
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    emailjs
      .send(
        "service_snb4gc4", // Reemplaza con tu Service ID para el correo al cliente
        "template_7cr8zcr", // Reemplaza con tu Template ID para el correo al cliente
        formData,
        "2b_1sHXeuhiy3gWpe" // Reemplaza con tu User ID para el correo al cliente
      )
      .then(() => {
        emailjs
          .send(
            "service_snb4gc4", // Reemplaza con tu Service ID para el correo al usuario
            "template_epkqun7", // Reemplaza con tu Template ID para el correo al usuario
            {
              ...formData,
              to_email: "mario.ca@construccionescorella.com", // Reemplaza con tu dirección de correo
            },
            "2b_1sHXeuhiy3gWpe" // Reemplaza con tu User ID para el correo al usuario
          )
          .then(() => {
            handleOpenModal();
            setToSend({
              from_name: "",
              phone: "",
              reply_to: "",
              message: "",
            });
          })
          .catch((error) => {
            console.error("Error al enviar el correo al usuario:", error);
          });
      })
      .catch((error) => {
        console.error("Error al enviar el correo al cliente:", error);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="card-contact" >
        <Col sm={"12"}>
          <input
            type="text"
            placeholder="Tu nombre"
            name="from_name"
            value={toSend.from_name}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.from_name}</p>
        </Col>
        <Col sm={"12"}>
          <input
            type="number"
            placeholder="Telefono"
            maxLength={10}
            name="phone"
            value={toSend.phone}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.phone}</p>
        </Col>
        <Col sm={"12"}>
          <input
            type="mail"
            placeholder="Correo"
            name="reply_to"
            value={toSend.reply_to}
            onChange={handleChange}
          />
          <p className="error-msj">{errors.reply_to}</p>
        </Col>
        <Col sm={"12"}>
          <textarea
            type="text"
            placeholder="Mensaje"
            name="message"
            value={toSend.message}
            onChange={handleChange}
          ></textarea>
          <p className="error-msj">{errors.message}</p>
        </Col>
        <Col sm={"12"}>
          <button
            type="submit"
            className="contact-btn-secondary"
            style={{ width: "100%" }}
          >
            Consigue una cotización
          </button>
        </Col>
      </form>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>¡Correo enviado con éxito!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¡Muchas gracias por tu preferencia!
          <br></br>
          <br></br>
          En un momento alguien de nuestro equipo se pondrá en contacto con
          usted.
        </Modal.Body>
        <Modal.Footer>
          <button
            className="contact-btn-secondary"
            onClick={handleCloseModal}
            style={{ width: "100%" }}
          >
            Entendido
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactForm;
