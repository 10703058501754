import React, { useEffect } from "react";
import About from "../sections/about";
import Caracteristics from "../sections/caracteristics";
import OurServices from "../sections/ourservices";
import Projects from "../sections/projects";
import OurJob from "../sections/ourjob";
import ContactUs from "../sections/contactus";
import Testimonials from "../sections/testimonials";
import NavbarSection from "../components/Navbar";
import WhatsAppButton from "../components/WhatsAppButton";
import Footer from "../sections/footer";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
function MainPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll hacia arriba al cargar la página
  }, []);
  return (
    <div className="header" id="home">
      <img className=""
        src="img/banner/header.png"
        style={{ position: "absolute",objectFit: "cover", zIndex: "-1" ,marginTop:"-50px",height:"1000px",width:"revert-layer"}}
      ></img>

      <NavbarSection />
      <div className="container banner banner-container" >
        <div className="content">
          <Col sm="8">
            <h1>Podemos convertir tus sueños en estructuras sólidas</h1>
            <p>
              En Corella Diseño y Construcción, transformamos tus ideas en
              realidades tangibles. Nuestro compromiso es construir con calidad,
              precisión y creatividad.
            </p>
            <Row>
              <Col sm="4">
                <a
                  href="#contact-us"
                  className="contact-btn-primary"
                  style={{ width: "10rem" }}
                >
                  Contáctanos
                </a>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
      <WhatsAppButton
        Href={"5216311130303"}
        Text={"Me gustaria recibir mas informacion"}
      />
      <Caracteristics />
      <OurServices />
      <Projects />
      <OurJob />
      <About />
      {/*<Testimonials />*/}
      <ContactUs />
      <Footer />
    </div>
  );
}

export default MainPage;
