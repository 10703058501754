import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import ContactForm from "../components/ContactForm";
function ContactUs() {
  return (
    <div
      id="contact-us"
      className="section-grey"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <div className="contact">
        <div className="container">
          <h1 className="yellow">
            Conéctate con <span>Nosotros</span>
          </h1>
          <Row>
            <Col sm={6}>
              <p>
                Estamos listos para escuchar tus ideas y convertirlas en
                proyectos tangibles. Nuestro equipo está disponible para
                asesorarte en cada paso del proceso de construcción.
              </p>
              <p>
                Ya sea que tengas preguntas, ideas específicas o simplemente
                quieras iniciar una conversación sobre tu próximo proyecto,
                estamos aquí para ti. Completa el formulario de contacto o
                llámanos directamente. Juntos, podemos hacer realidad tus sueños
                arquitectónicos.
              </p>
              <Row>
                <Col>
                  <div className="card-contacts">
                    <Row>
                      <Col>
                        <div className="line-yellow">
                          <h5 style={{ marginLeft: "15px" }}>
                            <strong>Teléfonos</strong>
                          </h5>
                        </div>
                        <div style={{ marginLeft: "15px", marginTop: "15px" }}>
                          <strong>Mario Corella</strong>
                          <p>+52 631 113 0303</p>

                          <strong>Martín Corella</strong>
                          <p>+52 631 126 56 52</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div className="card-contacts">
                    <Row>
                      <Col>
                        <div className="line-yellow">
                          <h5 style={{ marginLeft: "15px" }}>
                            <strong>Correos</strong>
                          </h5>
                        </div>
                        <div style={{ marginLeft: "15px", marginTop: "15px" }}>
                          <strong>Mario Corella</strong>
                          <p>mario.ca@construccionescorella.com</p>
                          <strong>Martín Corella</strong>
                          <p>martin.cv@construccionescorella.com</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <ContactForm />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
