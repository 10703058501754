import React from "react";
import { Link } from "react-router-dom";
function Card({ Title, Text, SubTile, Image, projectId }) {
  return (
    <Link to={`/proyectos/${projectId}`}>
      <div className="card-project">
        <div className="house-hover">
          <img
            style={{ height: "600px", width: "auto", borderRadius: "10px" }}
            src={Image}
          />
        </div>
        <div className="text">
          <div className="line-yellow">
            <div style={{ marginLeft: "15px" }}>
              <h2>{Title}</h2>
            </div>
          </div>
          <p>{Text}</p>
          <span>{SubTile}</span>
        </div>
      </div>
    </Link>
  );
}
export default Card;
