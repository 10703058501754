import React from "react";
import { BsWhatsapp } from "react-icons/bs";

const WhatsAppButton = ({ Href, Text }) => {
  return (
    <a
      href={`https://api.whatsapp.com/send/?phone=${Href}&amp;text=${Text}&amp;type=phone_number&amp;app_absent=0`}
      className="whatsAppButton"
      style={{ display: "flex" }}
    >
      <BsWhatsapp />
    </a>
  );
};

export default WhatsAppButton;
